import { RefObject } from 'react';

export function scrollToElementByID({
    yOffset = -80,
    id = 'default',
    behavior = 'smooth',
    refElement,
}: {
    yOffset?: number;
    id: string;
    behavior?: ScrollBehavior | undefined;
    refElement?: RefObject<HTMLDivElement | HTMLFormElement>;
}) {
    try {
        if (id === 'about') {
            window.scroll({
                top: 0,
                behavior: behavior,
            });
        } else {
            const element = document.getElementById(id);

            if (!element) return;

            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            if (refElement && refElement.current) {
                refElement.current.scrollIntoView({
                    block: 'center',
                    behavior: behavior,
                });
            } else {
                window.scroll({
                    top: y,
                    behavior: behavior,
                });
            }
        }
    } catch (e) {
        console.warn(`[Could not scroll in correct way]: ${id}`);
    }
}
