import useAppContext from '../../../providers/AppContext/AppContext.hooks';

import TeamDesktop from './TeamDesktop';
import TeamMobile from './TeamMobile';

const Team = () => {
    const { isMobile } = useAppContext();

    return isMobile ? <TeamMobile /> : <TeamDesktop />;
};

export default Team;
