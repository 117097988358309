import { useState } from 'react';

import { ReactComponent as MenuIcon } from '../../../../assets/icons/utils/menu.svg';

import MobileMenu from './MobileMenu';

const MenuMobileButton = (): JSX.Element => {
    const [openMobileMenu, setMobileMenu] = useState(false);

    const handleShowMenu = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
        document.body.style.overflow = 'hidden';
        setMobileMenu(true);
    };

    const handleHideMenu = () => {
        document.body.style.overflow = 'hidden auto';
        setMobileMenu(false);
    };

    return (
        <>
            <button id={'mobile-menu'} aria-label="Right Align" className={'mobile-menu'} onClick={handleShowMenu}>
                <MenuIcon />
            </button>
            {openMobileMenu && <MobileMenu handleHideMenu={handleHideMenu} />}
        </>
    );
};

export default MenuMobileButton;
