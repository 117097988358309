import { useTranslation } from 'react-i18next';

import Title from '../../../common/Title';
import { injectMarkupToHTML } from '../../../../functions/injectMarkupToHTML';
import Pic1W from '../../../../assets/images/industries/ind-1x.webp';
import Pic2W from '../../../../assets/images/industries/ind-2x.webp';
import Pic1 from '../../../../assets/images/industries/ind-1x.png';
import Pic2 from '../../../../assets/images/industries/ind-2x.png';

import { IndustriesData } from './Industries.data';

const IndustriesDesktop = () => {
    const { t } = useTranslation();

    return (
        <div className={'industries-layout'}>
            <div className={'industries-text-align-start'}>
                <Title isMobile={false} color={'white'} style={{ margin: 0 }}>
                    {t('menu.industries')}
                </Title>
                <div className={'industries-items'}>
                    <div className={'industries-item-desk'}>
                        {IndustriesData(t)[0].icon}
                        {injectMarkupToHTML(IndustriesData(t)[0].title)}
                    </div>
                    {IndustriesData(t)
                        .slice(1)
                        .map((item, idx) => (
                            <div key={idx} className={'industries-item-desk1'}>
                                {item.icon}
                                {injectMarkupToHTML(item.title)}
                            </div>
                        ))}
                </div>
                <div className={'ind-pic-div'}>
                    <picture className={'ind-pic'}>
                        <source srcSet={`${Pic1W} 1x, ${Pic2W} 2x`} type="image/webp" />
                        <source srcSet={`${Pic1} 1x, ${Pic2} 2x`} type="image/png" />
                        <img src={Pic1} alt={'about-pic'} className={'ind-pic'} />
                    </picture>
                </div>
            </div>
            <div className={'ind-bottom'} />
        </div>
    );
};

export default IndustriesDesktop;
