import './Title.styles.css';
import { CSSProperties } from 'react';

import { JSXChild, MobileCheck } from '../../../typings/common';

const Title = ({
    isMobile,
    children,
    id,
    color,
    style,
}: JSXChild & MobileCheck & { color: 'white' | 'black'; id?: string; style?: CSSProperties }): JSX.Element => (
    <p
        id={id}
        className={isMobile ? 'title-mobile' : 'title'}
        style={{ color: color === 'white' ? 'var(--WHITE)' : 'var(--TEXT_HEADER)', ...style }}
    >
        {children}
    </p>
);

export default Title;
