import useAppContext from '../../../providers/AppContext/AppContext.hooks';

import ExperienceMobile from './ExperienceMobile';
import ExperienceDesktop from './ExperienceDesktop';

const Experience = () => {
    const { isMobile } = useAppContext();

    return isMobile ? <ExperienceMobile /> : <ExperienceDesktop />;
};

export default Experience;
