import { useTranslation } from 'react-i18next';

import Title from '../../../common/Title';
import Fader from '../../../common/Fader';
import Card from '../../../common/Card';

import { TeamData } from './Team.data';

const TeamMobile = () => {
    const { t } = useTranslation();

    return (
        <div className={'team-layout-dense'} id={'team'}>
            <div className={'text-align-start-dense'}>
                <Title isMobile color={'black'}>
                    {t('menu.team')}
                </Title>
            </div>
            <Fader key={2}>
                <div className={'team-items-mob'}>
                    {TeamData(t).map((item, idx) => (
                        <Card
                            title={item.name}
                            subTitle={item.position}
                            src={item.src}
                            srcWebp={item.srcWebp}
                            url={item.url}
                            key={idx}
                            isMobile
                        />
                    ))}
                </div>
            </Fader>
        </div>
    );
};
export default TeamMobile;
