import { createContext, useState } from 'react';

import i18n from 'i18next';

import { ContactInterface, JSXChild } from '../../../typings/common';
import useInitiateI18 from '../../../hooks/configHooks/useInitiateI18';
import useMediaQuery from '../../../hooks/utilHooks/useMediaQuery';

import { AppContextTypings } from './AppContext.typings';

export const AppContext = createContext<AppContextTypings>(null);

const AppContextProvider = ({ children }: JSXChild): JSX.Element => {
    const isMobile = useMediaQuery('(max-width: 750px)');

    const [locale, setLocale] = useState(
        new URLSearchParams(window.location.search).get('locale')?.slice(0, 2) ?? navigator.language ?? 'eng',
    );

    useInitiateI18(locale);

    const handleChangeLocale = async (value: string): Promise<void> => {
        setLocale(value);
        await i18n.changeLanguage(value);
    };

    const [contact, setContact] = useState<ContactInterface>({
        name: '',
        email: '',
        comment: '',
    });

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = (): void => {
        document.body.style.overflow = 'hidden';
        setShowModal(true);
    };
    const handleHideModal = (): void => {
        document.body.style.overflow = 'hidden auto';
        setShowModal(false);
    };

    const value = {
        locale,
        handleChangeLocale,
        contact,
        setContact,
        showModal,
        handleShowModal,
        handleHideModal,
        isMobile,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
export default AppContextProvider;
