import { useTranslation } from 'react-i18next';

import Title from '../../../common/Title';
import Card from '../../../common/Card';
import { ReactComponent as Vector6Icon } from '../../../../assets/icons/figures/Vector-6.svg';
import { ReactComponent as Vector7Icon } from '../../../../assets/icons/figures/Vector-7.svg';
import Fader from '../../../common/Fader';

import { TeamData } from './Team.data';

const TeamDesktop = () => {
    const { t } = useTranslation();

    return (
        <div className={'team-layout'} id={'team'}>
            <div className={'text-align-start'}>
                <Title isMobile={false} color={'black'}>
                    {t('menu.team')}
                </Title>
            </div>
            <Fader key={2}>
                <div className={'team-items'}>
                    {TeamData(t).map((item, idx) => (
                        <Card
                            title={item.name}
                            subTitle={item.position}
                            src={item.src}
                            srcWebp={item.srcWebp}
                            url={item.url}
                            key={idx}
                        />
                    ))}
                </div>
            </Fader>
            <Vector6Icon style={{ position: 'absolute', top: '-15%', right: '20%', zIndex: 1 }} />
            <Vector7Icon style={{ position: 'absolute', top: '5%', left: '30%', zIndex: 1 }} />
        </div>
    );
};

export default TeamDesktop;
