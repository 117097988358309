import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/utils/close.svg';
import './MobileMenu.styles.css';
import Menu from '../Menu';
import LanguagePicker from '../../../common/LanguagePicker';
import { ReactComponent as LogoIcon } from '../../../../assets/icons/logo-white.svg';
import { scrollToElementByID } from '../../../../functions/scrollToElementByID';

const MobileMenu = ({ handleHideMenu }: { handleHideMenu: () => void }): JSX.Element => {
    const { t } = useTranslation();

    const handleSendForm = () => {
        handleHideMenu();
        scrollToElementByID({ id: 'contact', yOffset: 180 });
    };

    return (
        <div className={'mobile-menu-layout'}>
            <LogoIcon className={'mobile-menu-logo'} />
            <button className={'close-button-mobile'} onClick={handleHideMenu}>
                <CloseIcon />
            </button>
            <Menu isMobile styles={{ color: 'var(--TEXT_MENU)' }} handleHideMenu={handleHideMenu} />
            <br />
            <div style={{ marginTop: '13px' }} />
            <LanguagePicker />
            <button className={'mobile-menu-get-btn'} onClick={handleSendForm}>
                {t('buttons.primaryButton')}
            </button>
        </div>
    );
};

export default MobileMenu;
