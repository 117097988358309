import './PrimaryButton.styles.css';
import { ButtonHTMLAttributes } from 'react';

import { MobileCheck } from '../../../typings/common';

interface PrimaryButtonInterface extends MobileCheck, ButtonHTMLAttributes<any> {}

const PrimaryButton = (props: PrimaryButtonInterface): JSX.Element => (
    <button className={props.isMobile ? 'primary-button-dense' : 'primary-button'} {...props}>
        {props.children}
    </button>
);

export default PrimaryButton;
