import { useContext } from 'react';

import { AppContext } from './AppContext';
import { AppContextTypings } from './AppContext.typings';

function useAppContext() {
    const context = useContext<AppContextTypings>(AppContext);

    if (!context) {
        throw new Error('use only context only provider');
    }

    return {
        ...context,
    };
}

export default useAppContext;
