import useAppContext from '../../../providers/AppContext/AppContext.hooks';

import ServicesMobile from './ServicesMobile';
import ServicesDesktop from './ServicesDesktop';

const Services = () => {
    const { isMobile } = useAppContext();

    return isMobile ? <ServicesMobile /> : <ServicesDesktop />;
};

export default Services;
