import { useTranslation } from 'react-i18next';

import Title from '../../../common/Title';
import Fader from '../../../common/Fader';

const ExperienceDesktop = () => {
    const { t } = useTranslation();

    return (
        <div className={'experience-layout'} id={'experience'}>
            <div className={'experience-pic'}>
                <div className={'text-align-start'}>
                    <Title isMobile={false} color={'black'} style={{ maxWidth: '465px' }}>
                        {t('menu.experience')}
                    </Title>
                </div>
                <Fader key={4}>
                    <div className={'experience-items'}>
                        <div className={'experience-item-bold-1'}>
                            <b>01.</b>
                            <p>{t('experienceText.0')}</p>
                        </div>
                        <div className={'experience-item-bold-2'}>
                            <b>02.</b>
                            <p>{t('experienceText.1')}</p>
                        </div>
                        <div className={'experience-item-bold-3'}>
                            <b>03.</b>
                            <p>{t('experienceText.2')}</p>
                        </div>
                        <div className={'experience-item-bold-4'}>
                            <b>04.</b>
                            <p>{t('experienceText.3')}</p>
                        </div>
                        <div className={'experience-item-bold-5'}>
                            <b>05.</b>
                            <p>{t('experienceText.4')}</p>
                        </div>
                    </div>
                </Fader>
            </div>
        </div>
    );
};

export default ExperienceDesktop;
