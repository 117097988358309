import { useTranslation } from 'react-i18next';

import Title from '../../../common/Title';
import AutoSwipeableViewGallery from '../../../common/AutoSwipeable';
import 'swiper/css/bundle';

const ServicesMobile = () => {
    const { t } = useTranslation();

    return (
        <div className={'services-layout-dense'} id={'services'}>
            <div className={'text-align-start-dense'}>
                <Title isMobile color={'black'}>
                    {t('menu.services')}
                </Title>
            </div>
            <AutoSwipeableViewGallery t={t} />
        </div>
    );
};

export default ServicesMobile;
