import { i18nCallback } from '../../../../typings/common';
import { ReactComponent as Pic0 } from '../../../../assets/icons/services/1.svg';
import { ReactComponent as Pic1 } from '../../../../assets/icons/services/2.svg';
import { ReactComponent as Pic2 } from '../../../../assets/icons/services/3.svg';
import { ReactComponent as Pic3 } from '../../../../assets/icons/services/4.svg';
import { ReactComponent as Pic4 } from '../../../../assets/icons/services/5.svg';
import { ReactComponent as Pic5 } from '../../../../assets/icons/services/6.svg';
import { ReactComponent as Pic6 } from '../../../../assets/icons/services/7.svg';
import { ReactComponent as Pic7 } from '../../../../assets/icons/services/8.svg';

export const ServicesData = (t: i18nCallback) => [
    {
        title: t('servicesText.0'),
        icon: <Pic0 />,
    },
    {
        title: t('servicesText.1'),
        icon: <Pic1 />,
    },
    {
        title: t('servicesText.2'),
        icon: <Pic2 />,
    },
    {
        title: t('servicesText.3'),
        icon: <Pic3 />,
    },
    {
        title: t('servicesText.4'),
        icon: <Pic4 />,
    },
    {
        title: t('servicesText.5'),
        icon: <Pic5 />,
    },
    {
        title: t('servicesText.6'),
        icon: <Pic6 />,
    },
    {
        title: t('servicesText.7'),
        icon: <Pic7 />,
    },
];
