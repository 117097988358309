import { lazy, Suspense } from 'react';

import useAppContext from '../providers/AppContext/AppContext.hooks';

const ContactFormDesktop = lazy(() => import('./ContactFormDesktop'));
const ContactFormMobile = lazy(() => import('./ContactFormMobile'));

const ContactForm = (): JSX.Element => {
    const { showModal, handleHideModal, isMobile } = useAppContext();
    return (
        <Suspense>
            {showModal && !isMobile && <ContactFormDesktop handleHideModal={handleHideModal} />}
            {showModal && isMobile && <ContactFormMobile handleHideModal={handleHideModal} />}
        </Suspense>
    );
};
export default ContactForm;
