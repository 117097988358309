import { useCallback, useRef } from 'react';

import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';

import { i18nCallback } from '../../../typings/common';
import { ServicesData } from '../../Main/components/Services/Services.data';
import { injectMarkupToHTML } from '../../../functions/injectMarkupToHTML';
import './AutoSwipeable.styles.css';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/utils/arrow-back.svg';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const AutoSwipeableViewGallery = ({ t }: { t: i18nCallback }): JSX.Element => {
    const sliderRef = useRef<SwiperRef>(null);

    const handlePrev = useCallback(() => {
        sliderRef.current?.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        sliderRef.current?.swiper.slideNext();
    }, []);

    return (
        <Swiper
            ref={sliderRef}
            slidesPerView={'auto'}
            centeredSlides
            loop
            lazy
            // autoplay={{
            //     delay: 2500,
            //     disableOnInteraction: false,
            // }}
            loopedSlides={ServicesData.length + 1}
            spaceBetween={0}
        >
            {ServicesData(t).map((item, idx) => (
                <SwiperSlide key={idx}>
                    <div key={idx} className={'services-item-mobile'}>
                        {item.icon}
                        {injectMarkupToHTML(item.title)}
                    </div>
                </SwiperSlide>
            ))}
            <div className={'services-buttons'}>
                <div onClick={handlePrev} className={'services-button-l-mobile'}>
                    <ArrowIcon />
                </div>
                <div onClick={handleNext} className={'services-button-r-mobile'}>
                    <ArrowIcon />
                </div>
            </div>
        </Swiper>
    );
};

export default AutoSwipeableViewGallery;
