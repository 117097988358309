import './FooterMobile.styles.css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as VectorIcon } from '../../../assets/icons/figures/Vector-9.svg';
import { ReactComponent as Vector1Icon } from '../../../assets/icons/figures/Vector.svg';
import SendForm from '../../common/SendForm';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';

const FooterMobile = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <footer className={'footer-dense'} id={'contact'}>
            <div className={'footer-icon-div'}>
                <VectorIcon />
            </div>
            <div className={'footer-contact-us-text-dense'}>{t('menu.contactUs')}</div>
            <Vector1Icon style={{ position: 'absolute', top: '260px', left: '10%' }} />
            <SendForm
                isMobile
                buttonStyles={{
                    backgroundColor: 'var(--PRIMARY_MAIN)',
                    color: 'var(--WHITE)',
                    width: '194px',
                    height: '60px',
                    margin: '44px 0 125px',
                }}
            />
            <div className={'footer-address-block-mobile'}>
                <LogoIcon style={{ marginBottom: '30px' }} />
                <div className={'footer-address-text-mobile'}>{t('footer.address.0')}</div>
                <div className={'footer-address-text-mobile'}>{t('footer.address.1')}</div>
            </div>
        </footer>
    );
};

export default FooterMobile;
