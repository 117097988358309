import { ReactComponent as LogoIcon } from '../../assets/icons/logo-white.svg';
import './Header.styles.css';
import useAppContext from '../providers/AppContext/AppContext.hooks';
import LanguagePicker from '../common/LanguagePicker';

import Menu from './components/Menu';
import MenuMobileButton from './components/MobileMenu/MenuMobileButton';

const Header = (): JSX.Element => {
    const { isMobile } = useAppContext();

    return (
        <header className={isMobile ? 'header-dense' : 'header'}>
            {isMobile ? (
                <>
                    <LogoIcon style={{ height: '40px', width: '40px' }} />
                    <MenuMobileButton />
                </>
            ) : (
                <div className={'header-layout'}>
                    <div className={'header-layout-menu'}>
                        <LogoIcon style={{ height: '68px', width: '68px' }} />
                        <Menu styles={{ color: 'var(--WHITE)', margin: '0 20px', zIndex: 10, fontWeight: 600 }} />
                    </div>
                    <LanguagePicker />
                </div>
            )}
        </header>
    );
};
export default Header;
