import { useTranslation } from 'react-i18next';

import Title from '../../../common/Title';
import { injectMarkupToHTML } from '../../../../functions/injectMarkupToHTML';

import { ServicesData } from './Services.data';

const ServicesDesktop = () => {
    const { t } = useTranslation();

    return (
        <div className={'services-layout'} id={'services'}>
            <div className={'text-align-start'}>
                <Title isMobile={false} color={'black'}>
                    {t('menu.services')}
                </Title>
            </div>
            <div className={'services-items-bg'} />
            <div className={'services-items'}>
                {ServicesData(t).map((item, idx) => (
                    <div key={idx} className={'services-item'}>
                        {item.icon}
                        {injectMarkupToHTML(item.title)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServicesDesktop;
