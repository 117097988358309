import { useTranslation } from 'react-i18next';

import { injectMarkupToHTML } from '../../../../functions/injectMarkupToHTML';
import Title from '../../../common/Title';
import Pic1W from '../../../../assets/images/industries/ind-mob-1x.webp';
import Pic2W from '../../../../assets/images/industries/ind-mob-2x.webp';
import Pic1 from '../../../../assets/images/industries/ind-mob-1x.png';
import Pic2 from '../../../../assets/images/industries/ind-mob-2x.png';

import { IndustriesData } from './Industries.data';

const IndustriesMobile = () => {
    const { t } = useTranslation();

    return (
        <div className={'industries-layout-dense'}>
            <Title isMobile color={'white'} style={{ margin: 0 }}>
                {t('menu.industries')}
            </Title>
            <picture>
                <source srcSet={`${Pic1W} 1x, ${Pic2W} 2x`} type="image/webp" />
                <source srcSet={`${Pic1} 1x, ${Pic2} 2x`} type="image/png" />
                <img src={Pic1} alt={'industries-pic-mob'} className={'industries-pic-mob'} />
            </picture>
            <div className={'industries-items-dense'}>
                <div className={'industries-item-mob'}>
                    {IndustriesData(t)[0].icon}
                    {injectMarkupToHTML(IndustriesData(t)[0].title)}
                </div>
                {IndustriesData(t)
                    .slice(1)
                    .map((item, idx) => (
                        <div key={idx} className={'industries-item-mob-1'}>
                            {item.icon}
                            {injectMarkupToHTML(item.title)}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default IndustriesMobile;
