import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../../assets/locales/en/translations.json';
import translationPT from '../../assets/locales/pt/translations.json';

const fallbackLng = ['en', 'pt'];

const resources = {
    en: {
        translation: translationEN,
    },
    pt: {
        translation: translationPT,
    },
};

const useInitiateI18 = (locale: string): void => {
    i18n.use(initReactI18next).init({
        resources,
        fallbackLng,

        detection: {
            checkWhitelist: true,
        },

        cache: {
            enabled: true,
            prefix: 'i18next_translations_',
            expirationTime: 24 * 60 * 60 * 1000, //one day
        },

        lng: locale,

        debug: false,

        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
    });
};
export default useInitiateI18;
