import './Input.styles.css';
import { InputHTMLAttributes } from 'react';

type InputType = { isMobile?: boolean } & InputHTMLAttributes<any>;

const Input = ({ isMobile, placeholder, style, ...etc }: InputType): JSX.Element => (
    <div style={{ width: isMobile ? '100%' : 'calc(50% - 15px)' }}>
        <p className={'placeholder-style'}>{placeholder} </p>
        <input className={'input-style'} {...etc} style={{ ...style, maxWidth: isMobile ? '100%' : '' }} />
    </div>
);

export default Input;
