import './Card.styles.css';
import { injectMarkupToHTML } from '../../../functions/injectMarkupToHTML';
import { ReactComponent as LinkedIcon } from '../../../assets/icons/utils/linked.svg';

const Card = ({
    title,
    subTitle,
    src,
    srcWebp,
    url,
    isMobile = false,
}: {
    title: string;
    subTitle: string;
    src: string;
    srcWebp: string;
    url: string;
    isMobile?: boolean;
}) => (
    <div className={isMobile ? 'card-item-mob' : 'card-item'} onClick={() => (window.location.href = url)}>
        <div className={isMobile ? 'card-texts-mob' : 'card-texts'}>
            <p className={isMobile ? 'card-title-mob' : 'card-title'}>{injectMarkupToHTML(title)}</p>
            <p className={isMobile ? 'card-subtitle-mob' : 'card-subtitle'}>{injectMarkupToHTML(subTitle)}</p>
            <LinkedIcon
                className={'linked-icon'}
                style={isMobile ? { width: '18px', height: '18px' } : { width: '24px', height: '24px' }}
            />
        </div>
        <picture className={isMobile ? 'card-pic-mob' : 'card-pic'}>
            <source srcSet={srcWebp} type="image/webp" />
            <img src={src} alt={subTitle} className={isMobile ? 'card-pic-mob' : 'card-pic'} loading={'lazy'} />
        </picture>
    </div>
);

export default Card;
