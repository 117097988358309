import { About, Experience, Industries, Services, Team } from './components';
import './Main.styles.css';

const Main = (): JSX.Element => (
    <>
        <About />
        <Services />
        <Team />
        <Experience />
        <Industries />
    </>
);

export default Main;
