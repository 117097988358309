import useAppContext from '../../../providers/AppContext/AppContext.hooks';

import IndustriesMobile from './IndustriesMobile';
import IndustriesDesktop from './IndustriesDesktop';

const Industries = () => {
    const { isMobile } = useAppContext();

    return isMobile ? <IndustriesMobile /> : <IndustriesDesktop />;
};

export default Industries;
