import useAppContext from '../providers/AppContext/AppContext.hooks';

import FooterMobile from './FooterMobile';
import FooterDesktop from './FooterDesktop';

const Footer = (): JSX.Element => {
    const { isMobile } = useAppContext();
    return isMobile ? <FooterMobile /> : <FooterDesktop />;
};
export default Footer;
