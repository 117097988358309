import './FooterDesktop.styles.css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';
import Menu from '../../Header/components/Menu';
import SendForm from '../../common/SendForm';
import { ReactComponent as Vector1Icon } from '../../../assets/icons/figures/Vector-1.svg';
import { ReactComponent as Vector9Icon } from '../../../assets/icons/figures/Vector-9.svg';

const FooterDesktop = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <footer className={'footer'} id={'contact'}>
            <div className={'footer-layout'}>
                <div style={{ width: '100%', marginTop: '16px' }}>
                    <div className={'footer-contact-us-block'}>
                        <div className={'footer-flex'}>
                            <div className={'footer-contact-us-text'}>
                                {t('menu.contactUs')}
                                <Vector1Icon id={'v1'} />
                                <Vector9Icon id={'v9'} />
                            </div>

                            <SendForm
                                buttonStyles={{
                                    backgroundColor: 'var(--PRIMARY_MAIN)',
                                    color: 'var(--WHITE)',
                                    width: '194px',
                                    borderRadius: '20px',
                                    height: '60px',
                                    margin: '50px 0 90px',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'footer-layout'}>
                <div className={'footer-layout-logo'}>
                    <LogoIcon />
                </div>
                <div className={'footer-address-block'}>
                    <div className={'footer-address-text'} style={{ height: '32px' }}>
                        <b>{t('footer.address.0')}</b>
                    </div>
                    <div className={'footer-address-text'}>{t('footer.address.1')}</div>
                    {/*<div className={'footer-address-text'}>{t('footer.address.2')}</div>*/}
                </div>
            </div>
            <Menu secondaryStyle />
        </footer>
    );
};

export default FooterDesktop;
