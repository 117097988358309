export const injectMarkupToHTML = (str: string | null | undefined) => {
    try {
        let resultString = str;
        do {
            resultString = resultString?.replace('*', '<br/>') ?? '';
        } while (resultString.indexOf('*') !== -1);
        return <p dangerouslySetInnerHTML={{ __html: resultString }} style={{ margin: 0 }} />;
    } catch (e) {
        return str;
    }
};
