import { useTranslation } from 'react-i18next';

import Title from '../../../common/Title';
import Fader from '../../../common/Fader';
import { ReactComponent as Vector8Icon } from '../../../../assets/icons/figures/Vector-8.svg';
import { ReactComponent as Vector9Icon } from '../../../../assets/icons/figures/Vector-9.svg';

const ExperienceMobile = () => {
    const { t } = useTranslation();
    return (
        <div className={'experience-layout-dense'} id={'experience'}>
            <Vector9Icon style={{ position: 'absolute', top: '-8%', right: '5%', transform: 'rotate(90deg)' }} />
            <div className={'text-align-start-dense'}>
                <Title isMobile color={'black'} style={{ maxWidth: '305px' }}>
                    {t('menu.experience')}
                </Title>
            </div>
            <Fader key={4}>
                <div className={'experience-items-dense'}>
                    <div className={'experience-item-bold'}>
                        <b>01</b>
                        <p>{t('experienceText.0')}</p>
                    </div>
                    <div className={'experience-item-bold'}>
                        <b>02</b>
                        <p>{t('experienceText.1')}</p>
                    </div>
                    <div className={'experience-item-bold'}>
                        <b>03</b>
                        <p>{t('experienceText.2')}</p>
                    </div>
                    <div className={'experience-item-bold'}>
                        <b>04</b>
                        <p>{t('experienceText.3')}</p>
                    </div>
                    <div className={'experience-item-bold'}>
                        <b>05</b>
                        <p>{t('experienceText.4')}</p>
                    </div>
                </div>
            </Fader>
            <Vector8Icon style={{ position: 'absolute', top: '-10%', left: '55%' }} />
        </div>
    );
};

export default ExperienceMobile;
