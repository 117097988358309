import KonstantinKozlovsky from '../../../../assets/images/team/KonstantinKozlovsky.png';
import KonstantinKozlovskyWebp from '../../../../assets/images/team/KonstantinKozlovsky.webp';
import AlexeiParadzinsky from '../../../../assets/images/team/AlexeiParadzinsky.png';
import AlexeiParadzinskyWebp from '../../../../assets/images/team/AlexeiParadzinsky.webp';
import AlexeiKulevets from '../../../../assets/images/team/AlexeiKulevets.png';
import AlexeiKulevetsWebp from '../../../../assets/images/team/AlexeiKulevets.webp';
import { i18nCallback } from '../../../../typings/common';

export const TeamData = (t: i18nCallback) => [
    {
        name: t('teamText.members.0.name'),
        position: t('teamText.members.0.position'),
        src: AlexeiParadzinsky,
        srcWebp: AlexeiParadzinskyWebp,
        url: 'https://www.linkedin.com/in/aliakseiparadzinski/',
    },
    {
        name: t('teamText.members.1.name'),
        position: t('teamText.members.1.position'),
        src: AlexeiKulevets,
        srcWebp: AlexeiKulevetsWebp,
        url: 'https://www.linkedin.com/in/alexkulevets/',
    },
    {
        name: t('teamText.members.2.name'),
        position: t('teamText.members.2.position'),
        src: KonstantinKozlovsky,
        srcWebp: KonstantinKozlovskyWebp,
        url: 'https://www.linkedin.com/in/konstantin-kozlovski-071a9440/',
    },
];
