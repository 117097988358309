import { useTranslation } from 'react-i18next';

import useAppContext from '../../../providers/AppContext/AppContext.hooks';

import AboutMobile from './AboutMobile';
import AboutDesktop from './AboutDesktop';

const About = () => {
    const { t } = useTranslation();
    const { handleShowModal, isMobile } = useAppContext();

    return (
        <>
            {isMobile ? (
                <AboutMobile handleShowModal={handleShowModal} t={t} />
            ) : (
                <AboutDesktop handleShowModal={handleShowModal} t={t} />
            )}
        </>
    );
};

export default About;
