import './TransparentInput.styles.css';
import { InputHTMLAttributes } from 'react';

const TransparentInput = ({ placeholder, ...etc }: InputHTMLAttributes<any>): JSX.Element => (
    <div>
        <p className={'t-placeholder-style'}>{placeholder} </p>
        <input className={'t-input-style'} {...etc} />
    </div>
);

export default TransparentInput;
