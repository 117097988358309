import useAppContext from '../../providers/AppContext/AppContext.hooks';
import './LanguagePicker.css';

const LanguagePicker = (): JSX.Element => {
    const { locale, handleChangeLocale } = useAppContext();
    return (
        <select
            className={'select-style'}
            name={'lang'}
            id={'lang'}
            value={locale}
            onChange={(e) => handleChangeLocale(e.target.value)}
        >
            <optgroup>
                <option value="en">eng</option>
                <option value="pt">pt</option>
            </optgroup>
        </select>
    );
};
export default LanguagePicker;
