import { i18nCallback } from '../../../../typings/common';
import { ReactComponent as Pic0 } from '../../../../assets/icons/services/9.svg';
import { ReactComponent as Pic1 } from '../../../../assets/icons/services/10.svg';
import { ReactComponent as Pic2 } from '../../../../assets/icons/services/12.svg';
import { ReactComponent as Pic3 } from '../../../../assets/icons/services/11.svg';

export const IndustriesData = (t: i18nCallback) => [
    {
        title: t('industriesText.0'),
        icon: <Pic0 />,
    },
    {
        title: t('industriesText.1'),
        icon: <Pic1 />,
    },
    {
        title: t('industriesText.3'),
        icon: <Pic3 />,
    },
    {
        title: t('industriesText.2'),
        icon: <Pic2 />,
    },
];
