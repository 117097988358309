import { CSSProperties, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Input from '../Input';
import PrimaryButton from '../PrimaryButton';
import './SendForm.styles.css';
import '../Input/Input.styles.css';
import { EMAIL_REGEXP } from '../../../functions/emailRegExp';
import useAppContext from '../../providers/AppContext/AppContext.hooks';
import TransparentInput from '../TransparentInput';

const SendForm = ({
    isMobile = false,
    simpleForm = false,
    handleHideModal,
    buttonStyles,
}: {
    isMobile?: boolean;
    simpleForm?: boolean;
    handleHideModal?: () => void;
    buttonStyles?: CSSProperties;
}): JSX.Element => {
    const { t } = useTranslation();
    const { contact, setContact } = useAppContext();

    const [error, setError] = useState(false);

    const handleChange = (param: string, value: string) => {
        if (param === 'email' && !EMAIL_REGEXP.test(value)) {
            setError(true);
        }
        if (param === 'email' && EMAIL_REGEXP.test(value)) {
            setError(false);
        }

        setContact((prevState) => ({ ...prevState, [param]: value }));
    };

    const handleSubmit = () => {
        if (handleHideModal) {
            handleHideModal();
        }
        if (contact.email !== '') {
            window.open(`mailto:contact@blessed.software?subject=${contact.name}&body=${contact.comment}`);
            setContact({ name: '', email: '', comment: '' });
        }
    };

    if (simpleForm && !isMobile) {
        return (
            <>
                <TransparentInput
                    value={contact.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    placeholder={t('footer.contactForm.name') ?? ''}
                    style={{
                        width: 'calc(100% - 24px)',
                    }}
                />
                <div style={{ marginTop: '20px' }} />
                <TransparentInput
                    type="email"
                    style={{ border: error ? '1px solid red' : '', width: 'calc(100% - 24px)' }}
                    value={contact.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    placeholder={t('footer.contactForm.email') ?? ''}
                />
                <div style={{ marginTop: '20px' }} />
                <p className={'placeholder-style'}>{t('footer.contactForm.comment') ?? ''} </p>
                <textarea
                    onChange={(e) => handleChange('comment', e.target.value)}
                    value={contact.comment}
                    className={'textarea-style-modal'}
                    name="Text1"
                    cols={40}
                    rows={4}
                    style={{
                        width: 'calc(100% - 24px)',
                    }}
                />
                <div style={{ display: 'flex' }}>
                    <PrimaryButton isMobile={false} disabled={error} onClick={handleSubmit} style={buttonStyles}>
                        {t('buttons.send')}
                    </PrimaryButton>
                </div>
            </>
        );
    }

    if (simpleForm && isMobile) {
        return (
            <>
                <TransparentInput
                    value={contact.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    placeholder={t('footer.contactForm.name') ?? ''}
                    style={{
                        height: '48px',
                        width: 'calc(100% - 24px)',
                    }}
                />
                <div style={{ marginTop: '10px' }} />
                <TransparentInput
                    type="email"
                    style={{ border: error ? '1px solid red' : '', height: '48px', width: 'calc(100% - 24px)' }}
                    value={contact.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    placeholder={t('footer.contactForm.email') ?? ''}
                />
                <div style={{ marginTop: '10px' }} />
                <p className={'placeholder-style'}>{t('footer.contactForm.comment') ?? ''} </p>
                <textarea
                    onChange={(e) => handleChange('comment', e.target.value)}
                    value={contact.comment}
                    className={'textarea-style-modal'}
                    name="Text1"
                    cols={40}
                    rows={4}
                    style={{
                        width: 'calc(100% - 24px)',
                    }}
                />
                <div style={{ display: 'flex' }}>
                    <PrimaryButton isMobile disabled={error} onClick={handleSubmit} style={buttonStyles}>
                        {t('buttons.send')}
                    </PrimaryButton>
                </div>
            </>
        );
    }

    return (
        <div className={'contact-us-block'}>
            {isMobile ? (
                <>
                    <Input
                        isMobile
                        value={contact.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        placeholder={t('footer.contactForm.name') ?? ''}
                        style={{
                            height: '48px',
                            width: 'calc(100% - 24px)',
                        }}
                    />
                    <div style={{ marginBottom: '16px' }} />
                    <Input
                        isMobile
                        type="email"
                        style={{
                            height: '48px',
                            width: 'calc(100% - 24px)',
                            border: error ? '1px solid red' : '1px solid rgba(54, 62, 87, 0.2)',
                        }}
                        value={contact.email}
                        onChange={(e) => handleChange('email', e.target.value)}
                        placeholder={t('footer.contactForm.email') ?? ''}
                    />
                    <div style={{ marginBottom: '16px' }} />
                    <p className={'placeholder-style'}>{t('footer.contactForm.comment')} </p>
                    <textarea
                        onChange={(e) => handleChange('comment', e.target.value)}
                        value={contact.comment}
                        className={'textarea-style-mob'}
                        name="Text1"
                        cols={40}
                        rows={4}
                        style={{
                            width: 'calc(100% - 24px)',
                        }}
                    />
                    <PrimaryButton
                        isMobile
                        disabled={error || contact.email === ''}
                        onClick={handleSubmit}
                        style={buttonStyles}
                    >
                        {t('buttons.send')}
                    </PrimaryButton>
                </>
            ) : (
                <>
                    <div className={'contact-us-layout'}>
                        <Input
                            value={contact.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            placeholder={t('footer.contactForm.name') ?? ''}
                        />
                        <div style={{ marginRight: '30px', width: '30px' }} />
                        <Input
                            type="email"
                            style={{ border: error ? '1px solid red' : '' }}
                            value={contact.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                            placeholder={t('footer.contactForm.email') ?? ''}
                        />
                    </div>
                    <div style={{ marginTop: '30px' }} />
                    <p className={'placeholder-style'}>{t('footer.contactForm.comment') ?? ''} </p>
                    <textarea
                        onChange={(e) => handleChange('comment', e.target.value)}
                        value={contact.comment}
                        className={'textarea-style'}
                        name="Text1"
                        cols={40}
                        rows={4}
                    />
                    <PrimaryButton
                        isMobile={false}
                        disabled={error || contact.email === ''}
                        onClick={handleSubmit}
                        style={buttonStyles}
                    >
                        {t('buttons.send')}
                    </PrimaryButton>
                </>
            )}
        </div>
    );
};

export default SendForm;
