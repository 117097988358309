import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import AppContextProvider from './components/providers/AppContext';
import ContactForm from './components/ContactForm';

const App = () => (
    <AppContextProvider>
        <div className="App">
            <Header />
            <Main />
            <Footer />
            <ContactForm />
        </div>
    </AppContextProvider>
);

export default App;
