import '../Header.styles.css';
import { CSSProperties } from 'react';

import { useTranslation } from 'react-i18next';

import { scrollToElementByID } from '../../../functions/scrollToElementByID';

const Menu = ({
    styles,
    isMobile = false,
    handleHideMenu,
    secondaryStyle = false,
}: {
    styles?: CSSProperties;
    isMobile?: boolean;
    handleHideMenu?: () => void;
    secondaryStyle?: boolean;
}): JSX.Element => {
    const { t } = useTranslation();

    const handleClick = (id: string) => {
        if (handleHideMenu) {
            handleHideMenu();
        }
        scrollToElementByID({ id: id });
    };

    if (isMobile) {
        return (
            <div className={'menuLayoutDense'} style={styles}>
                <p className={'menuTextMobile'} onClick={() => handleClick('about')}>
                    {t('menu.about')}
                </p>
                <p className={'menuTextMobile'} onClick={() => handleClick('services')}>
                    {t('menu.services')}
                </p>
                <p className={'menuTextMobile'} onClick={() => handleClick('team')}>
                    {t('menu.team')}
                </p>
                <p className={'menuTextMobile'} onClick={() => handleClick('experience')}>
                    {t('menu.experience')}
                </p>
                <p className={'menuTextMobile'} onClick={() => handleClick('contact')}>
                    {t('menu.contact')}
                </p>
            </div>
        );
    }

    return (
        <div className={secondaryStyle ? 'menuLayout2' : 'menuLayout'} style={styles}>
            <p className={secondaryStyle ? 'menuText2' : 'menuText'} onClick={() => handleClick('about')}>
                {t('menu.about')}
            </p>
            <p className={secondaryStyle ? 'menuText2' : 'menuText'} onClick={() => handleClick('services')}>
                {t('menu.services')}
            </p>
            <p className={secondaryStyle ? 'menuText2' : 'menuText'} onClick={() => handleClick('team')}>
                {t('menu.team')}
            </p>
            <p className={secondaryStyle ? 'menuText2' : 'menuText'} onClick={() => handleClick('experience')}>
                {t('menu.experience')}
            </p>
            <p className={secondaryStyle ? 'menuText2' : 'menuText'} onClick={() => handleClick('contact')}>
                {t('menu.contact')}
            </p>
        </div>
    );
};

export default Menu;
