import Fader from '../../../common/Fader';
import Title from '../../../common/Title';
import { ReactComponent as VectorIcon } from '../../../../assets/icons/figures/Vector.svg';
import PrimaryButton from '../../../common/PrimaryButton';
import Pic1W from '../../../../assets/images/about/about-img.webp';
import Pic2W from '../../../../assets/images/about/about-img-2x.webp';
import Pic1 from '../../../../assets/images/about/about-img.png';
import Pic2 from '../../../../assets/images/about/about-img-2x.png';
import { i18nCallback } from '../../../../typings/common';

const AboutDesktop = ({ handleShowModal, t }: { handleShowModal: () => void; t: i18nCallback }) => (
    <div className={'about-div'}>
        <Fader key={0}>
            <div className={'about-layout-desktop'}>
                <div className={'about-text-layout'}>
                    <div className={'about-text-title'}>
                        <Title isMobile={false} color={'white'}>
                            {t('aboutText.highlighted')}
                            <VectorIcon className={'about-text-title-svg'} />
                        </Title>
                    </div>
                    <p className={'about-text'}>
                        <b>{t('aboutText.highlighted')}</b>
                        &nbsp;
                        {t('aboutText.normal')}
                    </p>
                    <PrimaryButton onClick={handleShowModal} isMobile={false}>
                        {t('buttons.primaryButton')}
                    </PrimaryButton>
                </div>
                <div className={'about-div-mrgn'}>
                    <picture className={'about-pic'}>
                        <source srcSet={`${Pic1W} 1x, ${Pic2W} 2x`} type="image/webp" />
                        <source srcSet={`${Pic1} 1x, ${Pic2} 2x`} type="image/png" />
                        <img src={Pic1} alt={'about-pic'} className={'about-pic'} loading={'lazy'} />
                    </picture>
                </div>
            </div>
        </Fader>
    </div>
);

export default AboutDesktop;
