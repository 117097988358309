import { useEffect, useRef, useState } from 'react';

import './Fader.styles.css';
import { JSXChild } from '../../../typings/common';

const Fader = ({ children }: JSXChild) => {
    const domRef: any = useRef();

    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            // In your case there's only one element to observe:
            if (entries[0].isIntersecting) {
                // Not possible to set it back to false like this:
                setVisible(true);

                // No need to keep observing:
                observer.unobserve(domRef.current);
            }
        });

        observer.observe(domRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <section ref={domRef} className={isVisible ? ' is-visible' : ''}>
            {children}
        </section>
    );
};
export default Fader;
